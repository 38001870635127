.accordion-component{
    padding: 20px 120px;
}
.accordion-component p{
    text-align: center;
    padding-top: 0;
    margin-bottom: 50px;
}
.default, .active{
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    color: #D29F55;
    padding: 20px;
    cursor: pointer;
}
.content{
    margin-top: 10px;
    margin-bottom: 30px;
    color: #E3E3E3;
    font-weight: 300;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: 0.03em;
    border-bottom: 1px solid #D29F55;
    padding: 20px;
}
.accordion-icon{
    font-size: 35px;
}
@media all and (max-width:500px){
    .accordion-component{
        padding: 20px 10px;
    }
    .default, .active{
        font-size: 1.2em;
    }
    .content{
        font-size: 1em;
    }
}