.price-packages-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.price-package{
    border: 1px solid white;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1em;
    width: 20rem;
    height: 400px;
}
.regular{
    padding-top: 6em;
}
.modal-price-package{
  font-size: 20px;
}
.modal_content-price{
    background-image: url('../../../assets/bg.jpg');
    padding: 50px;
    border-radius: 2px;
    overflow-y: auto;
    max-height: 100vh; 
}
.price-package {
    position: relative;
}
.modal-price-btn{
    background: transparent;
    border: none;
}
.ribbon-wrapper {
	position: relative;
    z-index:3;
    margin-bottom: 2em;
}
.ribbon-front {
	background-color:#D29F55;	
    height: 50px;
	width: 340px;
	position: relative;
	z-index: 2; 
    font-size: 20px;
    padding: .8em;
    color:#f8f8f8; 
    text-align:center;
    text-shadow: 0px 1px 2px #000000;
}

  .ribbon-front,
  .ribbon-back-left,
  .ribbon-back-right
{
	-moz-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-khtml-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-webkit-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-o-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
}

  .ribbon-edge-topleft,
  .ribbon-edge-topright,
  .ribbon-edge-bottomleft,
  .ribbon-edge-bottomright {
	position: absolute;
	z-index: 1;
	border-style:solid;
	height:0px;
	width:0px;
}

  .ribbon-edge-topleft,
  .ribbon-edge-topright {
}

  .ribbon-edge-bottomleft,
  .ribbon-edge-bottomright {
	top: 50px;
}

  .ribbon-edge-topleft,
  .ribbon-edge-bottomleft {
	left: 0;
	border-color: transparent #D29F55 transparent transparent;
}

  .ribbon-edge-topleft {
	top: -5px;
	border-width: 5px 10px 0 0;
}
  .ribbon-edge-bottomleft {
	border-width: 0 10px 0px 0;
}

  .ribbon-edge-topright,
  .ribbon-edge-bottomright {
	left: 330px;
	border-color: transparent transparent transparent #D29F55;
}

  .ribbon-edge-topright {
	top: 0px;
	border-width: 0px 0 0 10px;
}
  .ribbon-edge-bottomright {
	border-width: 0 0 10px 10px;
}


@-webkit-keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}
@keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}

.glow{ 
    background: rgb(255,255,255); 
    width: 150px; 
    height:100%; 
    z-index:6; 
    position:absolute;
            -webkit-animation: flow 1.5s linear infinite;
            -moz-animation: flow 1.5s linear infinite;
            -webkit-transform: skew(20deg);
            -moz-transform: skew(20deg);
            -o-transform: skew(20deg);background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%);
background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); 
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); 
background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );  
border-left:1px solid #fff;}
